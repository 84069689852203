import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import EventIcon from '@material-ui/icons/Event';

export default function ServicesPage() {
    return (
        <>
            <h1>Services!</h1>
            <Grid container spacing={3}>
                <Grid item lg={4}>
                    <Card variant={"elevation"}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                First Service
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque sapien. Quisque
                                tincidunt
                                scelerisque libero. Pellentesque ipsum.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color={"primary"}>
                                Book
                            </Button>
                            <Button  size="small" color={"primary"}>
                                Learn more
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item lg={4}>
                    <Card variant={"elevation"}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Second Service
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque sapien. Quisque
                                tincidunt
                                scelerisque libero. Pellentesque ipsum.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color={"primary"}>
                                Book
                            </Button>
                            <Button  size="small" color={"primary"}>
                                Learn more
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item lg={4}>
                    <Card variant={"elevation"}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Third Service
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque sapien. Quisque
                                tincidunt
                                scelerisque libero. Pellentesque ipsum.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color={"primary"}>
                                <EventIcon fontSize={"small"} />
                                Book
                            </Button>
                            <Button  size="small" color={"primary"}>
                                Learn more
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
