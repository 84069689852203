import { useStyles } from "../theme";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Link, useRouteMatch } from "react-router-dom";
import React from "react";

const routes = [
    {
        label: "Welcome",
        path: "/",
    },
    {
        label: "Services",
        path: "/services",
    },
    {
        label: "Reviews",
        path: "/reviews",
    },
    {
        label: "Contact & social media",
        path: "/contacts",
    }
]

export default function VerticalMenu(props) {
    const styles = useStyles();
    const { onClick } = props;

    let defaultValue = 0;

    const MatchRoute = (path) => { return useRouteMatch(path); }

    for (let i = 0; i < routes.length; i++) {
        if (MatchRoute(routes[i].path) !== null) {
            defaultValue = i;
        }
    }

    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (onClick) {
            onClick();
        }
    }

    return (
        <Tabs
            orientation={"vertical"}
            variant={"standard"}
            value={value}
            onChange={handleChange}
            aria-label={"Nav"}
            className={styles.navTabs}
        >
            {routes.map(((route, i) => (
                <Tab label={route.label} to={route.path} component={Link} key={i} />
            )))}
        </Tabs>
    )
}
