import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import { Menu as MenuIcon } from "@material-ui/icons";
import { AppBar, SwipeableDrawer } from "@material-ui/core";
import { useStyles } from "../theme.js";
import VerticalMenu from "./VerticalMenu";


function HeaderBar(props) {
    const classes = useStyles();
    const [showDrawer, setShowDrawer] = React.useState(false);

    const handleOpen = (event) => { console.log("Open!") };
    const handleClose = (event) => { setShowDrawer(false); };
    const handleClick = (event) => {
        setShowDrawer(!showDrawer);
    }

    return (
        <>
            <AppBar position={"static"}>
                <Toolbar>
                    <Hidden lgUp>
                        <IconButton
                            onClick={handleClick}
                            edge={"start"}
                            className={classes.menuButton}
                            color={"inherit"}
                            aria-label={"menu"}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Typography variant={"h6"} className={classes.title}>
                        Katka Web!
                    </Typography>
                </Toolbar>
            </AppBar>

            <SwipeableDrawer onClose={handleClose} onOpen={handleOpen} open={showDrawer} >
                <VerticalMenu onClick={() => {setShowDrawer(false);}} />
            </SwipeableDrawer>
        </>
    );
}

export default HeaderBar;