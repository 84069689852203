import { createMuiTheme, makeStyles } from "@material-ui/core";
import brown from "@material-ui/core/colors/brown";
import grey from "@material-ui/core/colors/grey";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: brown["300"]
        },
        secondary: {
            main: grey["500"]
        }
    }
})

export const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    navTabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        height: "100%"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    }
}));
