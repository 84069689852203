import React from "react";
import Grid from "@material-ui/core/Grid";
import {Hidden} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import VerticalMenu from "./Components/VerticalMenu";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import WelcomePage from "./Pages/Welcome";
import ContactsPage from "./Pages/Contacts";
import ReviewsPage from "./Pages/Reviews";
import ServicesPage from "./Pages/Services";
import HeaderBar from "./Components/HeaderBar";


function App() {
    return (
        <Router>
            <HeaderBar />
            <Grid container style={{ "height": "calc(100% - 64px)" }}>
                <Hidden mdDown>
                    <Grid item lg={2}>
                        <VerticalMenu defaultValue={1} />
                    </Grid>
                </Hidden>
                <Grid item lg={10} md={12}>
                    <Container>
                        <Switch>
                            <Route exact path={"/"} component={WelcomePage}/>
                            <Route path={"/services"} component={ServicesPage}/>
                            <Route path={"/reviews"} component={ReviewsPage}/>
                            <Route path={"/contacts"} component={ContactsPage}/>
                        </Switch>
                    </Container>
                </Grid>
            </Grid>
        </Router>
    );
}

export default App;
