import React from "react";

export default function WelcomePage() {
    return (
        <div>
            <h1>Welcome!</h1>
            <div>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque sapien. Quisque tincidunt
                    scelerisque libero. Pellentesque ipsum. Nam libero tempore, cum soluta nobis est eligendi optio
                    cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est,
                    omnis dolor repellendus. Proin in tellus sit amet nibh dignissim sagittis. Etiam bibendum elit eget
                    erat. Mauris tincidunt sem sed arcu. Maecenas sollicitudin. Nulla non lectus sed nisl molestie
                    malesuada. Phasellus et lorem id felis nonummy placerat. Aliquam in lorem sit amet leo accumsan
                    lacinia. Proin in tellus sit amet nibh dignissim sagittis. Curabitur bibendum justo non orci.
                    Curabitur vitae diam non enim vestibulum interdum. Duis bibendum, lectus ut viverra rhoncus, dolor
                    nunc faucibus libero, eget facilisis enim ipsum id lacus.</p>

                <p>Nulla pulvinar eleifend sem. Etiam quis quam. Duis viverra diam non justo. Duis bibendum, lectus ut
                    viverra rhoncus, dolor nunc faucibus libero, eget facilisis enim ipsum id lacus. Aliquam in lorem
                    sit amet leo accumsan lacinia. Cras elementum. Integer vulputate sem a nibh rutrum consequat. Aenean
                    vel massa quis mauris vehicula lacinia. Fusce tellus. Nulla non arcu lacinia neque faucibus
                    fringilla. Curabitur vitae diam non enim vestibulum interdum. Pellentesque arcu. Nullam at arcu a
                    est sollicitudin euismod.</p>

                <p>Quisque porta. Maecenas aliquet accumsan leo. Duis condimentum augue id magna semper rutrum.
                    Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Nulla non arcu lacinia neque
                    faucibus fringilla. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Etiam posuere lacus quis dolor. Aliquam erat volutpat. Suspendisse sagittis
                    ultrices augue. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Nulla pulvinar
                    eleifend sem. Nulla non lectus sed nisl molestie malesuada. Ut tempus purus at lorem. Nullam
                    feugiat, turpis at pulvinar vulputate, erat libero tristique tellus, nec bibendum odio risus sit
                    amet ante.</p>

                <p>Etiam bibendum elit eget erat. Maecenas aliquet accumsan leo. Proin in tellus sit amet nibh dignissim
                    sagittis. Integer tempor. Nullam rhoncus aliquam metus. Mauris metus. In convallis. Mauris tincidunt
                    sem sed arcu. Mauris metus. Nulla non lectus sed nisl molestie malesuada. Pellentesque pretium
                    lectus id turpis. Nulla quis diam. Integer tempor. Quis autem vel eum iure reprehenderit qui in ea
                    voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
                    nulla pariatur? Etiam neque. Aenean fermentum risus id tortor. Temporibus autem quibusdam et aut
                    officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et
                    molestiae non recusandae. Fusce nibh. Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas.</p>

                <p>Praesent dapibus. Nullam feugiat, turpis at pulvinar vulputate, erat libero tristique tellus, nec
                    bibendum odio risus sit amet ante. Vestibulum fermentum tortor id mi. Maecenas lorem. Sed elit dui,
                    pellentesque a, faucibus vel, interdum nec, diam. Curabitur ligula sapien, pulvinar a vestibulum
                    quis, facilisis vel sapien. Praesent dapibus. Nunc dapibus tortor vel mi dapibus sollicitudin. Sed
                    ac dolor sit amet purus malesuada congue. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                    aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                    Nunc dapibus tortor vel mi dapibus sollicitudin. Phasellus faucibus molestie nisl. Etiam bibendum
                    elit eget erat. In laoreet, magna id viverra tincidunt, sem odio bibendum justo, vel imperdiet
                    sapien wisi sed libero. Etiam egestas wisi a erat. Curabitur sagittis hendrerit ante. Nullam
                    feugiat, turpis at pulvinar vulputate, erat libero tristique tellus, nec bibendum odio risus sit
                    amet ante. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Integer in sapien.</p>

            </div>
        </div>
    );
}
