import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import {withStyles} from "@material-ui/core";

const StyledRating = withStyles({
    iconFilled: {
        color: "#ff6d75",
    },
    iconHover: {
        color: "#ff3d47",
    }
})(Rating);

export default function ReviewsPage() {
    return (
        <>
            <h1>Reviews Page!</h1>

            <Grid container spacing={3}>
                <Grid item lg={4}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar aria-label={"review"} className={null}>
                                    A
                                </Avatar>
                            }
                            title={"Alžběta"}
                            subheader={<Rating name={"review-a"} value={3} readOnly size={"small"}/>}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque sapien. Quisque
                                tincidunt
                                scelerisque libero. Pellentesque ipsum.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={4}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar aria-label={"review"} className={null}>
                                    B
                                </Avatar>
                            }
                            title={"Boris"}
                            subheader={"Feedback"}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque sapien. Quisque
                                tincidunt
                                scelerisque libero. Pellentesque ipsum.
                            </Typography>
                            <Box component={"fieldset"} mt={2} mb={-1} borderColor={"transparent"}>
                                <StyledRating name={"review-a"} value={4} readOnly
                                              icon={<FavoriteIcon fontSize="inherit"/>}
                                              emptyIcon={<FavoriteBorderIcon fontSize="inherit"/>}/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={4}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar aria-label={"review"}>
                                    C
                                </Avatar>
                            }
                            title={"Cecil"}
                            subheader={"Feedback"}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque sapien. Quisque
                                tincidunt
                                scelerisque libero. Pellentesque ipsum.
                            </Typography>
                            <Box component={"fieldset"} mt={2} mb={-1} borderColor={"transparent"}>
                                <Rating name={"review-a"} value={3} readOnly/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
